import gql from "graphql-tag";
import { Prompts } from "../fragments/prompts";
import { Connection } from "../fragments/connection";

export const fetchPrompts = gql`
  query fetchPrompts(
    $first: Int,
    $after: String,
    $appUuid: ID,
    $keyword: String,
    $action: String,
    $isAutomated: Boolean
    $sortBy: String,
    $sortDirection: String
  ) {
    fetchPrompts(
      first: $first,
      after: $after,
      appUuid: $appUuid,
      isAutomated: $isAutomated,
      keyword: $keyword,
      action: $action
      sortBy: $sortBy,
      sortDirection: $sortDirection,
      exclude: { extendedPromptType: "Prompts::BaseAgent" },
      includeErrors: true
    ) {
      edges {
        node {
          uuid
          app {
            name
          }
          error
          createdAt
          sentAt
          receivedAt
          promptTokens
          completionTokens
          promptPrice
          completionPrice
          action
          type
          extendedPrompt {
            ...on PromptProductDescription {
              uuid
            }
            ...on PromptCommentAutoreply {
              uuid
            }
            ...on PromptCompanyInformation {
              uuid
            }
            ...on PromptStoreLaunch {
              uuid
            }
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${Connection.fragments.pageInfo}
`;

export const fetchBasePrompt = gql`
  query fetchBasePrompt($promptUuid: ID!) {
    fetchBasePrompt(promptUuid: $promptUuid) {
      ...BasePrompt
    }
  }
  ${Prompts.fragments.base}
`;

export const fetchErrorPrompt = gql`
  query fetchBasePrompt($promptUuid: ID!) {
  fetchBasePrompt(promptUuid: $promptUuid) {
    error
      app {
        id
        name
        ownerFullName
        appOwnerEmail
        appOwnerContactNumber
        administratorFullNames
        appAdministratorEmails
        appAdministratorContactNumbers
      }
    }
  }
`;

export const fetchPrompt = gql`
  query fetchPrompt($promptUuid: ID!) {
    fetchPrompt(promptUuid: $promptUuid) {
      ...CommentAutoreply
      ...CompanyInformation
      ...ProductDescription
      ...StoreLaunch
    }
  }
  ${Prompts.fragments.commentAutoreply}
  ${Prompts.fragments.companyInformation}
  ${Prompts.fragments.productDescription}
  ${Prompts.fragments.storeLaunch}
`;
